/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Stack } from "immutable";
import * as React from "react";
import { AppProps } from "../../../../../App";
import { AnonymizerUI } from "../../../07-organisms/Anonymizer";
import { connect } from "react-redux";
import { Page, ReduxState } from "../../../../../store/ReduxState";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { hasChanged, updatePageMarked } from "../../../../../store/Page";
import { copy } from "../../../../../store/App";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { formatedCurrentDate as getFormattedDate } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/utils";

const MAX_WIDTH = 2048;
const MAX_HEIGHT = 2048;

/**
 *
 */
interface Point {
    x: number;
    y: number;
}

/**
 *
 */
export interface Rect {
    min: Point;
    max: Point;
}

/**
 *
 * @param r
 */
const dx = (r: Rect) => r.max.x - r.min.x;

/**
 *
 * @param r
 */
const dy = (r: Rect) => r.max.y - r.min.y;

/**
 *
 * @param r
 */
export const canonRect = (r: Rect): Rect => {
    const xmin = r.min.x;
    const ymin = r.min.y;
    const xmax = r.max.x;
    const ymax = r.max.y;
    return {
        min: {
            x: xmin < xmax ? xmin : xmax,
            y: ymin < ymax ? ymin : ymax,
        },
        max: {
            x: xmin > xmax ? xmin : xmax,
            y: ymin > ymax ? ymin : ymax,
        },
    };
};

interface DynamicCanvasSize {
    canvasContainerHeight: number;
    canvasContainerWidth: number;
}

/**
 *
 */
interface State extends DynamicCanvasSize {
    //
    drag?: {
        start: Point;
        current: Point;
    };

    move?: {
        start: Point;
        current: Point;
    };

    ctx2d?: {
        img: CanvasRenderingContext2D;
        watermark: CanvasRenderingContext2D;
        draw: CanvasRenderingContext2D;
        offscreen: CanvasRenderingContext2D;
    };

    canvasHeight: number;
    canvasWidth: number;

    previousScale: number;
    currentScale: number;

    zoomLevel: number;
    offsetX: number;
    offsetY: number;
    previousOffsetX: number;
    previousOffsetY: number;
    moveCanvas: boolean;

    loading: boolean;
    cancelling: boolean;
    saving: boolean;
}

/**
 *
 */
interface OwnProps {
    sourceImage: HTMLImageElement;
    loading: boolean;
    saving: boolean;
    width: number;
    height: number;
    onSave: (image: string[], nextPage: number) => void;
}

export interface AnonymizerPagination {
    pagination?: {
        current: number;
        total: number;
    };
}

interface StateProps {
    hasChanged: boolean;
    marked: Stack<Rect>;
    currentPageIndex: number;
    totalPageCount: number;
    pages: Page[];
}

interface DispatchProps {
    updatePageMarked: (marked: Stack<Rect>) => void;
    // updateCurrentPageIndex: (index: number) => void;
    onCopy: () => void;
}

/**
 *
 */
type Props = OwnProps & AppProps & AnonymizerPagination & StateProps & DispatchProps;

/**
 *
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    hasChanged: hasChanged(s),
    marked: s.prop("pages")[s.prop("currentPageIndex")]?.marked,
    currentPageIndex: s.prop("currentPageIndex"),
    totalPageCount: s.prop("totalPageCount"),
    pages: s.prop("pages"),
});

const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    updatePageMarked: (marked: Stack<Rect>) => {
        dispatch(updatePageMarked(marked));
    },
    // updateCurrentPageIndex: (index: number) => {
    //     dispatch(updateCurrentPageIndex(index));
    // },
    onCopy: () => {
        dispatch(copy());
    },
});

/**
 *
 */
class AnonymizerComp
    extends React.Component<Props, State> {

    /**
     *
     */
    private layerImgRef: React.RefObject<HTMLCanvasElement>;
    private layerWatermarkRef: React.RefObject<HTMLCanvasElement>;
    private layerDrawRef: React.RefObject<HTMLCanvasElement>;
    private layerOffscreenRef: React.RefObject<HTMLCanvasElement>;
    private canvasContainer: React.RefObject<HTMLDivElement>;

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.initState = this.initState.bind(this);
        this.contentCanvasSizeState = this.contentCanvasSizeState.bind(this);

        this.layerImgRef = React.createRef();
        this.layerWatermarkRef = React.createRef();
        this.layerDrawRef = React.createRef();
        this.layerOffscreenRef = React.createRef();

        this.canvasContainer = React.createRef();

        this.createWatermarks = this.createWatermarks.bind(this);

        this.selectStart = this.selectStart.bind(this);
        this.selectMove = this.selectMove.bind(this);
        this.selectEnd = this.selectEnd.bind(this);

        this.triggerUndo = this.triggerUndo.bind(this);
        this.triggerClear = this.triggerClear.bind(this);
        this.triggerCancel = this.triggerCancel.bind(this);
        this.triggerUseDrawTool = this.triggerUseDrawTool.bind(this);
        this.triggerUseMoveTool = this.triggerUseMoveTool.bind(this);
        this.triggerZoomIn = this.triggerZoomIn.bind(this);
        this.triggerZoomOut = this.triggerZoomOut.bind(this);

        this.calcZoom = this.calcZoom.bind(this);

        this.useMoveTool = this.useMoveTool.bind(this);

        this.anonymizeCurrentPage = this.anonymizeCurrentPage.bind(this);

        this.state = this.initState();
    }

    /**
     *
     * @param ctx
     * @param stack
     */
    private static drawRects(
        ctx: CanvasRenderingContext2D,
        stack: Stack<Rect>,
        scaleFactor: number,
        isDragging: boolean) {

        ctx.save();
        ctx.fillStyle = "#000000";
        if (isDragging) {
            ctx.strokeStyle = "rgba(102, 228, 238, .75)";
            ctx.lineWidth = 1 * scaleFactor;
        }
        stack.reverse().forEach((rect, index) => {
            if (isDragging && index === stack.size - 1) {
                ctx.fillStyle = "rgba(0, 0, 0, .75)";
            }
            ctx.fillRect(
                rect.min.x,
                rect.min.y,
                dx(rect),
                dy(rect),
            );

            if (isDragging && index === stack.size - 1) {
                ctx.fillStyle = "#000000";
                ctx.strokeRect(
                    rect.min.x,
                    rect.min.y,
                    dx(rect),
                    dy(rect),
                );

                ctx.fillStyle = "rgba(102, 228, 238, 1)";
                ctx.beginPath();
                ctx.arc(rect.min.x, rect.min.y, 2 * scaleFactor, 0, 2 * Math.PI);
                ctx.fill();
                ctx.beginPath();
                ctx.arc(rect.max.x, rect.min.y, 2 * scaleFactor, 0, 2 * Math.PI);
                ctx.fill();
                ctx.beginPath();
                ctx.arc(rect.max.x, rect.max.y, 2 * scaleFactor, 0, 2 * Math.PI);
                ctx.fill();
                ctx.beginPath();
                ctx.arc(rect.min.x, rect.max.y, 2 * scaleFactor, 0, 2 * Math.PI);
                ctx.fill();
            }
        });
        ctx.restore();
    }

    /**
     *
     */
    public componentDidMount() {
        const layerImg = Mary.utils.ensure(this.layerImgRef.current || undefined);
        const layerWatermark = Mary.utils.ensure(this.layerWatermarkRef.current || undefined);
        const layerDraw = Mary.utils.ensure(this.layerDrawRef.current || undefined);
        const layerOffscreen = Mary.utils.ensure(this.layerOffscreenRef.current || undefined);

        this.setState({
            ctx2d: {
                img: Mary.utils.ensure(layerImg.getContext("2d") || undefined),
                watermark: Mary.utils.ensure(layerWatermark.getContext("2d") || undefined),
                draw: Mary.utils.ensure(layerDraw.getContext("2d") || undefined),
                offscreen: Mary.utils.ensure(layerOffscreen.getContext("2d") || undefined),
            },
        }, () => {
            this.scaleToFit();
        });
    }

    /**
     *
     */
    public componentDidUpdate(prevProps: Props, _prevState: State) {
        const contentCanvasSize = this.contentCanvasSizeState();
        const initState = this.initState();

        if ((this.state.canvasContainerHeight !== contentCanvasSize.canvasContainerHeight ||
            this.state.canvasContainerWidth !== contentCanvasSize.canvasContainerWidth)
            && prevProps.sourceImage !== this.props.sourceImage) {

            this.setState({
                ...initState,
                ...contentCanvasSize,
            },
            () => {
                this.scaleToFit();
            },
            );
        } else if (this.state.canvasContainerHeight !== contentCanvasSize.canvasContainerHeight ||
            this.state.canvasContainerWidth !== contentCanvasSize.canvasContainerWidth) {

            this.setState({
                ...contentCanvasSize,
            },
            () => {
                this.scaleToFit();
            },
            );
        } else if (prevProps.sourceImage !== this.props.sourceImage || ((prevProps.saving !== this.props.saving) && this.props.saving)) {
            this.setState({
                ...initState,
            },
            () => {
                this.scaleToFit(this.props.saving ? () => this.anonymizeCurrentPage(this.props.currentPageIndex + 1) : undefined);
            },
            );
        }

        this.drawMarks();
    }

    /**
     *
     */
    public render() {
        return (
            <AnonymizerUI
                compact={this.props.width <= 440}

                onStart={this.selectStart}
                onMove={this.selectMove}
                onEnd={this.selectEnd}

                usingMoveTool={this.state.moveCanvas}

                loadingIndication={{
                    loading: this.state.loading,
                    cancelling: this.state.cancelling,
                    saving: this.state.saving,
                }}

                toolbar={{
                    onUseDrawTool: this.triggerUseDrawTool,
                    usingDrawTool: !this.state.moveCanvas,

                    onUseMoveTool: this.triggerUseMoveTool,

                    onZoomOut: this.triggerZoomOut,
                    disableZoomOut: this.state.zoomLevel <= .75,

                    onZoomIn: this.triggerZoomIn,
                    disableZoomIn: this.state.zoomLevel >= 4,

                    onUndo: this.triggerUndo,
                    disableUndo: this.props.marked?.isEmpty(),

                    onCopy: this.props.onCopy,
                    disableCopyBtn: this.props.marked?.isEmpty(),
                    isMultiPage: this.props.pages.length > 1,

                    onClear: this.triggerClear,
                    disableClearBtn: this.props.marked?.isEmpty(),
                }}

                content={{
                    zoomLevel: this.state.zoomLevel,
                    isMoving: !!this.state.move,
                    canvasWidth: this.state.canvasWidth,
                    canvasHeight: this.state.canvasHeight,
                    layerImgRef: this.layerImgRef,
                    layerOffscreenRef: this.layerOffscreenRef,
                    layerWatermarkRef: this.layerWatermarkRef,
                    layerDrawRef: this.layerDrawRef,
                    canvasContainerRef: this.canvasContainer,
                    canvasScale: this.state.currentScale,
                    offsetX: this.state.offsetX,
                    offsetY: this.state.offsetY,
                }}

                navigation={{
                    onSave: this.anonymizeCurrentPage,
                    totalPageCount: this.props.totalPageCount,
                    currentPageIndex: this.props.currentPageIndex,
                    isMultiPage: this.props.pages.length > 1,
                    disableSave: this.state.loading || this.state.saving || this.state.cancelling,
                    onCancel: this.triggerCancel,
                    saveButtonLabel: this.props.pagination
                        ?
                        <>
                            <React.Fragment>
                                {(this.props.pagination.current !== this.props.pagination.total) ? "Volgende " : "Opslaan "}
                                <span className="scl-h-text--tiny">
                                    {"(" + this.props.pagination.current + "/" + this.props.pagination.total + ")"}
                                </span>
                            </React.Fragment>
                        </>
                        : undefined,
                }}
            />
        );
    }

    private scaleToFit(onSuccess?: () => void) {
        if (this.props.sourceImage) {

            const image = this.props.sourceImage;
            const calculatedImageSizes = this.calculateMaxImageScale(image);

            const scale = Math.min(
                Mary.utils.ensure(this.canvasContainer.current || undefined).clientWidth
                / calculatedImageSizes.width,
                Mary.utils.ensure(this.canvasContainer.current || undefined).clientHeight
                / calculatedImageSizes.height);

            this.createWatermarks(image);

            this.setState({
                canvasWidth: calculatedImageSizes.width,
                canvasHeight: calculatedImageSizes.height,
                currentScale: scale,
                previousScale: this.state.currentScale,
                loading: this.props.saving,
            }, () => {
                Mary.utils.ensure(this.state.ctx2d)
                    .img
                    .drawImage(
                        image,
                        0,
                        0,
                        calculatedImageSizes.width,
                        calculatedImageSizes.height,
                    );
                if (onSuccess) {
                    onSuccess();
                }
            });

        }
    }

    private calculateMaxImageScale(image: HTMLImageElement): { width: number; height: number } {
        let scale = 1;
        if (MAX_WIDTH < image.width || MAX_HEIGHT < image.height) {
            scale = Math.min(MAX_WIDTH
                / image.width, MAX_HEIGHT / image.height);
        }
        return { width: image.width * scale, height: image.height * scale };
    }

    private triggerZoomIn(_evt: React.MouseEvent | React.TouchEvent) {
        if (this.state.zoomLevel <= 4) {
            this.setState({
                offsetX: 0,
                offsetY: 0,
                previousOffsetX: 0,
                previousOffsetY: 0,
                zoomLevel: this.state.zoomLevel + this.calcZoom(true),
            });
        }
    }

    private triggerZoomOut(_evt: React.MouseEvent | React.TouchEvent) {
        if (this.state.zoomLevel >= 1) {
            this.setState({
                offsetX: 0,
                offsetY: 0,
                previousOffsetX: 0,
                previousOffsetY: 0,
                zoomLevel: this.state.zoomLevel - this.calcZoom(false),
            });
        }
    }

    private calcZoom(zoomIn: boolean) {
        if (zoomIn) {
            if (this.state.zoomLevel <= 1) {
                return .25;
            } else if (this.state.zoomLevel === 1.25) {
                return .5;
            } else if (this.state.zoomLevel === 1.75) {
                return 1;
            } else {
                return 1.5;
            }
        } else {
            if (this.state.zoomLevel >= 4.25) {
                return 1.5;
            } else if (this.state.zoomLevel === 2.75) {
                return 1;
            } else if (this.state.zoomLevel === 1.75) {
                return .5;
            } else {
                return .25;
            }
        }
    }

    private useMoveTool(move: boolean) {
        if (this.state.moveCanvas !== move) {
            this.setState({
                moveCanvas: move,
                drag: move ? undefined : this.state.drag,
            });
        }
    }

    private triggerUseDrawTool(_evt: React.MouseEvent | React.TouchEvent) {
        this.useMoveTool(false);
    }

    private triggerUseMoveTool(_evt: React.MouseEvent | React.TouchEvent) {
        this.useMoveTool(true);
    }

    private calcScaleFactor() {
        const zoomLevel = 1 / this.state.zoomLevel;
        const currentScale = 1 / this.state.currentScale;

        return currentScale * zoomLevel;
    }

    private getCoordinatesSelect(evt: React.MouseEvent | React.TouchEvent): Point {
        const layerDraw = Mary.utils.ensure(this.layerDrawRef.current || undefined);
        const layerDrawDimensions = layerDraw.getBoundingClientRect();

        const clientX = (!(evt.nativeEvent instanceof MouseEvent))
            ? evt.nativeEvent.touches[0].clientX : evt.nativeEvent.clientX;

        const clientY = (!(evt.nativeEvent instanceof MouseEvent))
            ? evt.nativeEvent.touches[0].clientY : evt.nativeEvent.clientY;

        const offset = {
            x: ((clientX - layerDrawDimensions.left) * this.calcScaleFactor()),
            y: ((clientY - layerDrawDimensions.top) * this.calcScaleFactor()),
        };

        return offset;
    }

    private getCoordinatesMove(evt: React.MouseEvent | React.TouchEvent): Point {
        const canvasContainer = Mary.utils.ensure(this.canvasContainer.current || undefined);
        const canvasContainerDimensions = canvasContainer.getBoundingClientRect();

        const clientX = (!(evt.nativeEvent instanceof MouseEvent))
            ? evt.nativeEvent.touches[0].clientX : evt.nativeEvent.clientX;

        const clientY = (!(evt.nativeEvent instanceof MouseEvent))
            ? evt.nativeEvent.touches[0].clientY : evt.nativeEvent.clientY;
        const zoomLevel = 1 / this.state.zoomLevel;
        const offset = {
            x: ((clientX - canvasContainerDimensions.left)) * zoomLevel,
            y: ((clientY - canvasContainerDimensions.top)) * zoomLevel,
        };

        return offset;
    }

    private selectStart(evt: React.MouseEvent | React.TouchEvent) {
        if (this.props.sourceImage) {
            if (!(evt.nativeEvent instanceof MouseEvent)) {
                evt.preventDefault();
            }

            if (this.state.moveCanvas) {
                const offset = this.getCoordinatesMove(evt);
                this.setState({
                    move: {
                        start: offset,
                        current: offset,
                    },
                });
            } else {
                const offset = this.getCoordinatesSelect(evt);
                this.setState({
                    drag: {
                        start: offset,
                        current: offset,
                    },
                });
            }
        }
    }

    private selectMove(evt: React.MouseEvent | React.TouchEvent) {
        /**
         *
         * @param v
         * @param min
         * @param max
         * @returns the number contained within the canvas
         */
        const clamp = (v: number, min: number, max: number): number => {
            if (v > max) {
                return max;
            }
            if (v < min) {
                return min;
            }
            return v;
        };

        if (this.props.sourceImage) {
            //
            // update the current mouse co-ord and re-draw
            const drag = this.state.drag;
            const move = this.state.move;
            if (!drag && !move) {
                return;
            }

            if (!(evt.nativeEvent instanceof MouseEvent)) {
                evt.preventDefault();
            }

            if (drag) {
                const offset = this.getCoordinatesSelect(evt);

                this.setState({
                    drag: {
                        start: drag.start,
                        current: {
                            x: clamp(offset.x, 0, this.state.canvasWidth),
                            y: clamp(offset.y, 0, this.state.canvasHeight),
                        },
                    },
                });
            } else if (move) {
                const offset = this.getCoordinatesMove(evt);

                const offsetX = move.start.x > offset.x
                    ? -(Math.abs(move.start.x - offset.x)) : (Math.abs(move.start.x - offset.x));
                const offsetY = move.start.y > offset.y
                    ? -(Math.abs(move.start.y - offset.y)) : (Math.abs(move.start.y - offset.y));
                this.setState({
                    offsetX: offsetX + this.state.previousOffsetX,
                    offsetY: offsetY + this.state.previousOffsetY,
                    move: {
                        start: move.start,
                        current: {
                            x: clamp(offset.x, 0, Mary.utils.ensure(this.canvasContainer.current || undefined).clientWidth),
                            y: clamp(offset.y, 0, Mary.utils.ensure(this.canvasContainer.current || undefined).clientHeight),
                        },
                    },
                });
            }
        }
    }

    private createWatermarks(image: HTMLImageElement) {
        // const wtrkOffscreen = ensure(this.state.ctx2d).watermarkOffscreen;
        const calculatedImageSizes = this.calculateMaxImageScale(image);
        const wtrk = Mary.utils.ensure(this.state.ctx2d).watermark;
        const imgWidth = calculatedImageSizes.width;
        const imgHeight = calculatedImageSizes.height;

        // draw watermark
        const date = getFormattedDate(new Date());
        const watermark = `${this.props.watermarkLabel ? (this.props.watermarkLabel + " - "): ""}${date}`;
        if (!!watermark) {
            wtrk.save();
            wtrk.globalAlpha = 0.4;
            wtrk.clearRect(0, 0, imgWidth, imgHeight);
            wtrk.textAlign = "center";
            wtrk.textBaseline = "middle";

            wtrk.font = "140px Sans-serif";
            wtrk.strokeStyle = "rgba(0, 0, 0, .65)";

            const textSize = wtrk.measureText(watermark);
            const textScale = imgWidth / textSize.width;

            Math.min(imgWidth
                / textSize.width, imgHeight / textSize.width);

            const angle = Math.PI / 8;  // amount to rotate in radians

            if (textScale < 1) {
                wtrk.translate((imgWidth / 2), (imgHeight / 2));
                wtrk.scale(textScale, textScale);
                wtrk.rotate(angle);
                wtrk.translate(-(imgWidth / 2), -(imgHeight / 2));
            } else {
                wtrk.translate(imgWidth / 2, imgHeight / 2);
                wtrk.rotate(angle);
                wtrk.translate(- imgWidth / 2, - imgHeight / 2);
            }

            wtrk.lineWidth = 4;
            wtrk.strokeText(watermark, imgWidth / 2, imgHeight / 2);

            wtrk.fillStyle = "rgba(255, 255, 255, 1)";

            wtrk.fillText(watermark, imgWidth / 2, imgHeight / 2);

            wtrk.restore();
        }
    }

    private selectEnd(_evt: React.MouseEvent | React.TouchEvent) {
        if (this.props.sourceImage) {
            // commit the select and
            const drag = this.state.drag;
            const move = this.state.move;
            if (!drag && !move) {
                return;
            }

            this.props.updatePageMarked(this.markedWithDrag());
            this.setState({
                drag: undefined,
                move: undefined,
                previousOffsetX: this.state.offsetX,
                previousOffsetY: this.state.offsetY,
            });
        }
    }

    /**
     * This will only undo the changes on the current page!
     * TODO: refactor so that we don't need mouse events.
     *
     * @param _evt
     *
     */
    private triggerUndo(_evt: React.MouseEvent | React.TouchEvent) {
        // pop from the stack, redraw
        this.props.updatePageMarked(this.props.marked.pop());
    }

    /**
     *
     * @param evt
     */
    private triggerClear(_evt: React.MouseEvent | React.TouchEvent) {
        this.triggerUndo("" as any);
        // clear stack, redraw
        this.props.updatePageMarked(Stack());
        this.setState({
            zoomLevel: 1,
            offsetX: 0,
            offsetY: 0,
            previousOffsetX: 0,
            previousOffsetY: 0,
        });
    }

    private triggerCancel(_evt: React.MouseEvent | React.TouchEvent) {
        this.setState({
            cancelling: true,
        });
        this.props.onCancel?.();
    }

    private anonymizeCurrentPage(nextPage: number) {
        if (!this.props.sourceImage) {
            return;
        }

        const imgHeight = this.state.canvasHeight;
        const imgWidth = this.state.canvasWidth;

        this.setState({
            saving: true,
        }, () => {
            const osc = Mary.utils.ensure(this.state.ctx2d).offscreen;
            const watermark = Mary.utils.ensure(this.state.ctx2d).watermark;
            const img = Mary.utils.ensure(this.state.ctx2d).img;

            // clean canvas
            osc.clearRect(0, 0, imgWidth, imgHeight);

            // draw original
            osc.drawImage(
                img.canvas,
                0,
                0,
                imgWidth,
                imgHeight,
            );

            // draw marking rects
            AnonymizerComp.drawRects(osc,
                this.props.marked,
                this.calcScaleFactor(),
                this.showDragRectangle(),
            );

            // draw watermarks
            osc.drawImage(
                watermark.canvas,
                0,
                0,
                imgWidth,
                imgHeight,
            );

            const dstImg = new Image();
            setTimeout(() => {
                dstImg.src = Mary.utils.ensure(this.layerOffscreenRef.current || undefined).toDataURL("image/jpeg", 0.85);
                this.props.onSave([dstImg.src], nextPage);
            }, 32);
        });
    }

    private showDragRectangle() {
        return !!this.state.drag
            && !(this.state.drag.start.x === this.state.drag.current.x
                && this.state.drag.start.y === this.state.drag.current.y);
    }

    /**
     *
     */
    private drawMarks() {
        if (this.props.sourceImage) {
            const ctx = Mary.utils.ensure(this.state.ctx2d).draw;

            ctx.clearRect(0, 0, this.state.canvasWidth, this.state.canvasHeight);

            AnonymizerComp.drawRects(ctx,
                this.markedWithDrag(),
                this.calcScaleFactor(),
                this.showDragRectangle(),
            );
        }
    }

    /**
     *
     */
    private markedWithDrag(): Stack<Rect> {
        const drag = this.state.drag;
        const marked = this.props.marked;
        if (!drag) {
            return marked;
        }

        if (drag.start.x === drag.current.x && drag.start.y === drag.current.y) {
            return marked;
        } else {
            const dragRect = canonRect({ min: drag.start, max: drag.current });
            return this.props.marked.push(dragRect);
        }
    }

    private contentCanvasSizeState(): DynamicCanvasSize {
        const canvasContainer = Mary.utils.ensure(this.canvasContainer.current || undefined);

        return {
            canvasContainerHeight: canvasContainer.clientHeight,
            canvasContainerWidth: canvasContainer.clientWidth,
        };
    }

    private initState(): State {
        return {
            move: undefined,
            drag: undefined,
            canvasContainerHeight: this.props.height,
            canvasContainerWidth: this.props.width,
            canvasHeight: this.props.height,
            canvasWidth: this.props.width,
            currentScale: 1,
            previousScale: 1,
            zoomLevel: 1,
            moveCanvas: false,
            offsetX: 0,
            offsetY: 0,
            previousOffsetX: 0,
            previousOffsetY: 0,
            loading: this.props.loading,
            cancelling: false,
            saving: this.props.saving,
        };
    }
}

/**
 *
 */
export const AnonymizerWrapper = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AnonymizerComp);
