/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { DragNDropArea } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms/DragNDropArea";
import React from "react";
import { DropzoneOptions, ErrorCode, useDropzone } from "react-dropzone";

interface OwnProps {
}
/**
 *
 */
type Props = OwnProps & DropzoneOptions;


export const FileErrorMessageDisplay: Readonly<Record<ErrorCode, string>> = {
    [ErrorCode.FileTooLarge]: "Het bestand is te groot.",
    [ErrorCode.FileTooSmall]: "Het bestand is te klein.",
    [ErrorCode.TooManyFiles]: "U probeert te veel bestanden toe te voegen.",
    [ErrorCode.FileInvalidType]: "U mag dit bestandstype niet toevoegen.",
};


export const DragNDrop: React.FunctionComponent<Props> = props => {
    const { getRootProps, getInputProps } = useDropzone({ ...props });

    return (
        <>
            <DragNDropArea wrapper={getRootProps} input={getInputProps}/>
        </>
    );
};
