/* eslint-disable max-len */
import * as React from "react";
import * as ReactDOM from "react-dom";

import {App} from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { I18n } from "@aws-amplify/core";
import {
    BrowserRouter as Router, Route, RouteComponentProps, Switch,
} from "react-router-dom";
import { createReduxStore } from "./store/ReduxStore";
import { Provider } from "react-redux";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";


I18n.configure(Mary.utils.I18nMarylocales);
I18n.putVocabularies({
    nl: {
        ...Mary.utils.I18nMaryTranslations.nl,
        "app.app-title": "Anonymize",
        "app.save": "Opslaan",
        "app.copy": "Pas de maskering toe op alle pagina's",
        "app.draw-copy": "De maskering is nu toegepast op alle pagina's",
        "app.retry": "Verwijder de maskering van de afbeelding",
        "app.retry-success": "De maskering is van de afbeelding verwijderd",
        "app.applyWatermark": "Toon watermerk",
        "app.draw": "Hiermee kunt u de maskering aanbrengen om te anonimiseren",
        "app.draw-success": "U kunt nu de maskering toepassen",
        "app.move": "Hiermee kunt u de afbeelding wat verplaatsen",
        "app.move-success": "U kunt de afbeelding nu verplaatsen",
        "app.zoomin": "Hiermee kunt u de afbeelding inzoomen",
        "app.zoomout": "Hiermee kunt u de afbeelding uitzoomen",
        "app.undo": "Maak de laatst geplaatste maskering ongedaan",
        "app.undo-success": "De laatste wijziging is ongedaan gemaakt",
        "app.loadingMessage": "Bezig met laden..",
        "app.cancellingMessage": "Bezig met annuleren..",
        "app.savingMessage": "Bezig met opslaan..",
        "app.general": "Algemeen",
        "app.yes": "Ja",
        "app.no": "Nee",
        "app.pdf": "PDF",
        "app.csv": "CSV",
        "app.make-a-choice": "Maak een keuze",
        "app.view-pdf": "Bekijk PDF",
        "app.go-to-link": "Ga naar link",
        "app.home.title": "Welkom,",
        "app.home.description": "U kunt alles doen wat te maken heeft met het beheer van de API's",
        "app.remote-error": "Claude kan niet aan uw aanvraag voldoen.",
        "app.fatal-remote-error": "Zelfs Claude weet niet wat er mis is!",
        "app.remote-init-error": "Geen API beschikbaar.",
        "app.remote-init-message":
            "Claude kan geen verbinding met de API maken.",
        "app.notification": "Notificatie",
        "app.warning": "Waarschuwing",
    },
});

const store = createReduxStore({
    apiEndpoint: "",
});

export const Anonymizer = (elm: HTMLElement) => {
    ReactDOM.render(
        <Mary.base.BaseProvider
            theme={Mary.theme.Themes.CLAUDE}
            language={Mary.utils.I18nLanguage.DUTCH}
            dynamic
            className="scl-h-background scl-h-color--contrast-secondary scl-b-app"
        >
            <Mary.utils.ErrorBoundary>
                <Provider store={store}>
                    <>
                        <Router>
                            <Switch>
                                <Route
                                    render={(p: RouteComponentProps ) => (
                                        <Mary.base.BaseConsumer>{({ setTheme, themeIsLoaded }) => (
                                            <App
                                                routeProps={p?.location?.pathname} setTheme={setTheme} themeIsLoaded={themeIsLoaded}
                                            />
                                        )}
                                        </Mary.base.BaseConsumer>
                                    )}
                                />
                            </Switch>
                        </Router>
                        <Mary.base.PreLoader/>
                    </>
                </Provider>
            </Mary.utils.ErrorBoundary>
        </Mary.base.BaseProvider>,
        elm,
    );
};

export const MaryComp = {
    Anonymizer,
};

const elm = document.getElementById("root") as HTMLElement;
MaryComp.Anonymizer(elm);

serviceWorkerRegistration.unregister();

