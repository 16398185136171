/* eslint-disable @typescript-eslint/restrict-plus-operands */
import * as React from "react";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

/**
 *
 */
interface Props {
    className?: string;
    onSave: (nextPage: number) => void;
    onCancel: (e: React.MouseEvent | React.TouchEvent) => void;
    totalPageCount: number;
    currentPageIndex: number;
    disableSave: boolean;
    isMultiPage: boolean;
    disableContinue?: boolean;
    saveButtonLabel?: string | React.ReactNode;
    compact?: boolean;
}

/**
 *
 * @param props
 */
export const AnonymizerNavigation: React.FunctionComponent<Props> =
    (props) => {
        const clazzName = Mary.utils.joinClasses(
            "scl-o-anonymizer__navigation",
            props.className,

        );

        return (
            <div className={clazzName}>
                <div className="scl-o-anonymizer__navigation-content">
                    <div className="scl-o-anonymizer__navigation-content--left">
                        <div className="scl-m-btn-container scl-m-btn-container--left">
                            <Mary.atoms.button.Button
                                link={{ onClick: props.onCancel }}
                                className={"scl-a-btn scl-a-btn--brand-link"
                                    + (props.compact ? " scl-a-btn--tiny" : "")}
                                style={props.compact ? {
                                    marginLeft: "0",
                                    paddingLeft: "0",
                                } : {}}
                            >
                                {Mary.utils.getText("mary.cancel", "Cancel")}
                            </Mary.atoms.button.Button>
                        </div>
                    </div>
                    {props.isMultiPage && (
                        <Mary.molecules.pagination.Pagination
                            pageCount={props.totalPageCount}
                            currentPage={props.currentPageIndex}
                            onPageChange={props.onSave}
                            theme={{ palette:  Mary.theme.ThemePalette.CONTRAST_PRIMARY }}
                        />
                    )}
                    <div className="scl-o-anonymizer__navigation-content--right">
                        <div className="scl-m-btn-container scl-m-btn-container--right">
                            <Mary.atoms.button.Button
                                link={{ onClick: () => props.onSave(props.currentPageIndex + 1) }}
                                theme={{ palette:  Mary.theme.ThemePalette.BRAND_ACCENT }}
                                className={(props.compact ? "scl-a-btn--tiny" : "")}
                                disabled={props.disableSave}
                            >
                                {props.saveButtonLabel
                                    ? props.saveButtonLabel : Mary.utils.getText("app.save", "Save")}
                            </Mary.atoms.button.Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
