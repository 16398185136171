/* eslint-disable @typescript-eslint/require-await */
import { Action } from "redux";
import { ActionTypeKeys, Thunk } from "./ActionTypes";
import { clearState, ReducerFn } from "./ReduxState";


export type ClearAction = Readonly<
Action<ActionTypeKeys.CLEAR_STATE>>;

export const clear: () => Thunk<void> =
     () => async (dispatch, _getState) => {
         dispatch({
             type: ActionTypeKeys.CLEAR_STATE,
         });
     };


export const clearReducer:
ReducerFn<ClearAction> =
(s, _a) => (
    clearState(s)
);

export type CopyAction = Readonly<
Action<ActionTypeKeys.COPY>>;

export const copy: () => Thunk<void> =
     () => async (dispatch, _getState) => {
         dispatch({
             type: ActionTypeKeys.COPY,
         });
     };


export const copyReducer:
ReducerFn<CopyAction> =
(s, _a) => {
    const currentPage = s.prop("pages")[s.prop("currentPageIndex")];
    const pages = s.prop("pages").map((p) => {
        if(p !== currentPage) {
            return {
                img: p.img,
                marked: p.marked.push(...currentPage.marked),
                processedFile: p.processedFile,
            };
        } else {
            return p;
        }
    });
    return s.setProp("pages", pages);
};

