/* eslint-disable @typescript-eslint/require-await */
import { Stack } from "immutable";
import { Action } from "redux";
import { Rect } from "../App/components/09-views/02-pages/Anonymizer/AnonymizerWrapper";
import { ActionTypeKeys, Thunk } from "./ActionTypes";
import { ReducerFn, ReduxState } from "./ReduxState";

export type UpdatePageImgElmAction = Readonly<
Action<ActionTypeKeys.UPDATE_PAGE_IMG> & {
    img: HTMLImageElement;
    proccessedImg: string;
}>;

export const updatePageImgElm: (img: HTMLImageElement, proccessedImg: string) => Thunk<void> =
     (img, proccessedImg) => async (dispatch, _getState) => {
         dispatch({
             type: ActionTypeKeys.UPDATE_PAGE_IMG,
             img,
             proccessedImg,
         });
     };


export const updatePageImgElmReducer:
ReducerFn<UpdatePageImgElmAction> =
(s, a) => (
    s.setProp("pages", [...s.prop("pages"), { img: a.img, marked: Stack(), processedFile: a.proccessedImg }])
);

export type UpdatePageProcessedFileAction = Readonly<
Action<ActionTypeKeys.UPDATE_PAGE_PROCESSED_FILE> & {
    file: string;
}>;

export const updatePageProcessedFile: (file: string) => Thunk<void> =
     (file) => async (dispatch, _getState) => {
         dispatch({
             type: ActionTypeKeys.UPDATE_PAGE_PROCESSED_FILE,
             file,
         });
     };

export const updatePageProcessedFileReducer:
ReducerFn<UpdatePageProcessedFileAction> =
(s, a) => {
    const pages = s.prop("pages").map((p, i) => {
        if(i === s.prop("currentPageIndex")) {
            return {
                img: p.img,
                marked: p.marked,
                processedFile: a.file,
            };
        } else {
            return p;
        }
    });
    return s.setProp("pages", pages);
};

export type UpdatePageMarkedAction = Readonly<
Action<ActionTypeKeys.UPDATE_PAGE_MARKED> & {
    marked: Stack<Rect>;
}>;

export const updatePageMarked: (marked: Stack<Rect>) => Thunk<void> =
     (marked) => async (dispatch, _getState) => {
         dispatch({
             type: ActionTypeKeys.UPDATE_PAGE_MARKED,
             marked,
         });
     };

export const updatePageMarkedReducer:
ReducerFn<UpdatePageMarkedAction> =
(s, a) => {
    const pages = s.prop("pages").map((p, i) => {
        if(i === s.prop("currentPageIndex")) {
            return {
                img: p.img,
                marked: a.marked,
                processedFile: p.processedFile,
            };
        } else {
            return p;
        }
    });
    return s.setProp("pages", pages);
};

export type TotalCountAction = Readonly<
Action<ActionTypeKeys.UPDATE_TOTAL_COUNT> & {
    count: number;
}>;

export const totalCount: (count: number) => Thunk<void> =
     (count) => async (dispatch, _getState) => {
         dispatch({
             type: ActionTypeKeys.UPDATE_TOTAL_COUNT,
             count,
         });
     };


export const totalCountElmReducer:
ReducerFn<TotalCountAction> =
(s, a) => (
    s.setProp("totalPageCount", a.count)
);

export type UpdateCurrentPageIndexAction = Readonly<
Action<ActionTypeKeys.UPDATE_CURRENT_PAGE_INDEX> & {
    index: number;
}>;

export const updateCurrentPageIndex: (index: number) => Thunk<void> =
     (index) => async (dispatch, _getState) => {
         dispatch({
             type: ActionTypeKeys.UPDATE_CURRENT_PAGE_INDEX,
             index,
         });
     };


export const updateCurrentPageIndexReducer:
ReducerFn<UpdateCurrentPageIndexAction> =
(s, a) => (
    s.setProp("currentPageIndex", a.index)
);

export const hasChanged = (s: ReduxState) => (s.prop("pages").filter(p => p.marked.size > 0)).length >= 1;
