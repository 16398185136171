/* eslint-disable max-len */
import * as React from "react";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { Div } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/03-base";
import { RTE } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms";

/**
 *
 */
interface Props {
    className?: string;
    // onTogglePreview: (e: React.MouseEvent | React.TouchEvent) => void;
    // showPreview: boolean;

    onUseDrawTool: (e: React.MouseEvent | React.TouchEvent) => void;
    usingDrawTool: boolean;

    onUseMoveTool: (e: React.MouseEvent | React.TouchEvent) => void;
    usingMoveTool: boolean;

    onZoomOut: (e: React.MouseEvent | React.TouchEvent) => void;
    disableZoomOut: boolean;

    onZoomIn: (e: React.MouseEvent | React.TouchEvent) => void;
    disableZoomIn: boolean;

    onUndo: (e: React.MouseEvent | React.TouchEvent) => void;
    disableUndo: boolean;

    onCopy: (e: React.MouseEvent | React.TouchEvent) => void;
    disableCopyBtn: boolean;
    isMultiPage: boolean;

    onClear: (e: React.MouseEvent | React.TouchEvent) => void;
    disableClearBtn: boolean;
}

const NotificationTrigger: React.FunctionComponent<{message: string; onShow: (m: string) => void; onHide: () => void; options?: {round?: boolean; disabled?: boolean}}> = (props) => (
    <div style={{borderRadius: props.options?.round ? "100%" : undefined}}
        onMouseEnter={props.options?.disabled ? undefined : () => props.onShow(props.message)}
        onMouseLeave={() => {
            props.onHide();
        }}
    >{props.children}
    </div>);

/**
 *
 * @param props
 */
export const AnonymizerToolbar: React.FunctionComponent<Props> =
    (props) => {
        const [store, setStore] = React.useState<{showNotification?: {
            message: string;
            update?: NodeJS.Timeout;
        };}>({showNotification: undefined});
        const clazzName = Mary.utils.joinClasses(
            "scl-o-anonymizer__tools",
            props.className,

        );

        const showNotification = (v: string, u?: boolean) => {
            if (store.showNotification?.update === undefined || u) {
                if (u && !!store?.showNotification?.update) {
                    clearTimeout(store.showNotification.update);
                }
                setStore({
                    showNotification: {
                        message: v,
                        update: u ? setTimeout(() =>
                            setStore({
                                showNotification: undefined,
                            }), 3500) : undefined,
                    },
                });
            }
        };

        const clearNotification = () => {
            if (store.showNotification?.update === undefined) {
                setStore({
                    showNotification: undefined,
                });
            }
        };

        return (
            <>
                <Mary.organisms.navbar.Navbar
                    theme={{
                        palette: Mary.theme.ThemePalette.CONTRAST_TERTIARY,
                    }}
                    className={clazzName}
                >
                    <Mary.organisms.NavbarStack>
                        <Mary.organisms.NavbarStackLeft>
                            <Mary.base.Div
                                theme={{ padding: { "": { l: 2 }, "sm": { l: 3 } } }}
                            >
                                {props.isMultiPage ?
                                    <Mary.base.Div style={{display: "flex"}}>
                                        <NotificationTrigger options={{round: true, disabled: props.disableCopyBtn}} message={Mary.utils.getText("app.copy", "Apply the masking to all pages") ?? ""} onHide={clearNotification} onShow={showNotification}>
                                            <Mary.atoms.button.Button
                                                link={{ onClick: (e) => {
                                                    showNotification(Mary.utils.getText("app.draw-copy", "Copy") ?? "", true);
                                                    return props.onCopy(e);
                                                }}}
                                                title={Mary.utils.getText("app.copy", "Apply the masking to all pages")}
                                                theme={{
                                                    palette: props.disableCopyBtn
                                                        ? Mary.theme.ThemePalette.CONTRAST_TERTIARY
                                                        : Mary.theme.ThemePalette.CONTRAST_PRIMARY,
                                                }}
                                                disabled={props.disableCopyBtn}
                                                icon={"fas fa-copy"}
                                            />
                                        </NotificationTrigger>
                                    </Mary.base.Div> : ""}
                            </Mary.base.Div>
                        </Mary.organisms.NavbarStackLeft>
                        <Mary.organisms.NavbarStackContent>
                            <Mary.base.Div
                                className="scl-o-anonymizer__tools-icons"
                                style={{ display: "inline-flex", justifyContent: "center"}}
                            >
                                <NotificationTrigger options={{round: true}} message={Mary.utils.getText("app.draw", "Activate to use draw tool") ?? ""} onHide={clearNotification} onShow={showNotification}>
                                    <Mary.atoms.button.Button
                                        link={{ onClick: (e) => {
                                            showNotification(Mary.utils.getText("app.draw-success", "Draw") ?? "", true);
                                            return props.onUseDrawTool(e);
                                        }}}
                                        icon={Mary.atoms.IconNames.OBJECT}
                                        theme={{
                                            palette: !props.usingDrawTool
                                                ? Mary.theme.ThemePalette.CONTRAST_TERTIARY
                                                : Mary.theme.ThemePalette.BRAND_ACCENT,
                                        }}
                                        title={Mary.utils.getText("app.draw", "Activate to use draw too")}
                                    />
                                </NotificationTrigger>
                                <NotificationTrigger options={{round: true}} message={Mary.utils.getText("app.move", "Slide") ?? ""} onHide={clearNotification} onShow={showNotification}>
                                    <Mary.atoms.button.Button
                                        link={{ onClick: (e) => {
                                            showNotification(Mary.utils.getText("app.move-success", "Move") ?? "", true);
                                            return props.onUseMoveTool(e);
                                        }}}
                                        icon={Mary.atoms.IconNames.MOVE}
                                        theme={{
                                            palette: !props.usingMoveTool
                                                ? Mary.theme.ThemePalette.CONTRAST_TERTIARY
                                                : Mary.theme.ThemePalette.BRAND_ACCENT,
                                        }}
                                        title={Mary.utils.getText("app.move", "Slide")}
                                    />
                                </NotificationTrigger>
                                <NotificationTrigger options={{round: true, disabled: props.disableZoomOut}} message={Mary.utils.getText("app.zoomout", "Zoom out") ?? ""} onHide={clearNotification} onShow={showNotification}>
                                    <Mary.atoms.button.Button
                                        link={{ onClick: (e) => {
                                            clearNotification();
                                            return props.onZoomOut(e);
                                        }}}
                                        icon={Mary.atoms.IconNames.ZOOM_OUT}
                                        theme={{ palette: Mary.theme.ThemePalette.CONTRAST_TERTIARY }}
                                        disabled={props.disableZoomOut}
                                        title={Mary.utils.getText("app.zoomout", "Zoom out")}
                                    />
                                </NotificationTrigger>
                                <NotificationTrigger options={{round: true, disabled: props.disableZoomIn}} message={Mary.utils.getText("app.zoomin", "Zoom in") ?? ""} onHide={clearNotification} onShow={showNotification}>
                                    <Mary.atoms.button.Button
                                        link={{ onClick: (e) => {
                                            clearNotification();
                                            return props.onZoomIn(e);
                                        }}}
                                        icon={Mary.atoms.IconNames.ZOOM_IN}
                                        theme={{ palette: Mary.theme.ThemePalette.CONTRAST_TERTIARY }}
                                        disabled={props.disableZoomIn}
                                        title={Mary.utils.getText("app.zoomin", "Zoom in")}
                                    />
                                </NotificationTrigger>
                            </Mary.base.Div>
                        </Mary.organisms.NavbarStackContent>
                        <Mary.organisms.NavbarStackRight>
                            <Mary.base.Div
                                className="scl-o-anonymizer__tools-icons"
                                style={{ display: "inline-flex", justifyContent: "right"}}
                            >
                                <Mary.base.Div>
                                    <NotificationTrigger options={{round: true, disabled: props.disableUndo}} message={Mary.utils.getText("app.undo", "Undo") ?? ""} onHide={clearNotification} onShow={showNotification}>
                                        <Mary.atoms.button.Button
                                            link={{ onClick: (e) => {
                                                showNotification(Mary.utils.getText("app.undo-success", "Undo") ?? "", true);
                                                return props.onUndo(e);
                                            }}}
                                            icon={Mary.atoms.IconNames.UNDO}
                                            theme={{
                                                palette: props.disableUndo
                                                    ? Mary.theme.ThemePalette.CONTRAST_TERTIARY
                                                    : Mary.theme.ThemePalette.CONTRAST_PRIMARY,
                                            }}
                                            disabled={props.disableUndo}
                                            title={Mary.utils.getText("app.undo", "Undo")}
                                        />
                                    </NotificationTrigger>
                                </Mary.base.Div>
                                <Mary.base.Div style={{ marginRight: "20px"}}>
                                    <NotificationTrigger options={{round: true, disabled: props.disableClearBtn}} message={Mary.utils.getText("app.retry", "Retry") ?? ""} onHide={clearNotification} onShow={showNotification}>
                                        <Mary.atoms.button.Button
                                            link={{ onClick: (e) => {
                                                showNotification(Mary.utils.getText("app.retry-success", "Retry") ?? "", true);
                                                return props.onClear(e);
                                            }}}
                                            icon={Mary.atoms.IconNames.DELETE}
                                            theme={{
                                                palette: props.disableClearBtn
                                                    ? Mary.theme.ThemePalette.CONTRAST_TERTIARY
                                                    : undefined,
                                                paletteState: props.disableClearBtn
                                                    ? undefined
                                                    : Mary.theme.ThemePaletteState.DANGER,
                                            }}
                                            disabled={props.disableClearBtn}
                                            title={Mary.utils.getText("app.retry", "Retry")}
                                        />
                                    </NotificationTrigger>
                                </Mary.base.Div>
                            </Mary.base.Div>
                        </Mary.organisms.NavbarStackRight>
                    </Mary.organisms.NavbarStack>
                    {!!store.showNotification && (
                        <Div style={{
                            position: "absolute",
                            zIndex: 3,
                            width: "100%",
                            textAlign: "center",
                            backgroundColor: (store.showNotification?.update !== undefined ? "color-mix(in srgb, var(--scl-color-state-brand--success), transparent 10%)" : "color-mix(in srgb, var(--scl-color-brand--accent), transparent 10%)" ),
                            animationName: "scl-fade-in",
                            animationDuration: ".3s",
                        }}
                        >
                            <Div theme={{padding:{ "": { y: 1, x: 2 }, "sm": { y: 1, x: 3 } }  }}>
                                <RTE>
                                    <b
                                        className={store.showNotification?.update !== undefined ? "scl-h-text--tiny scl-h-foreground--contrast-state-success" : "scl-h-text--tiny scl-h-foreground--contrast-accent"}>
                                        {store.showNotification.message}</b>
                                </RTE>
                            </Div>
                        </Div>
                    )}
                </Mary.organisms.navbar.Navbar>
            </>
        );
    };
