import * as React from "react";

import { Anonymizer } from "./App/components/09-views/02-pages/Anonymizer";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { StandAloneAnonymizer } from "./App/components/09-views/02-pages/StandAloneAnonymizer";
import { ThemeContext } from "./App/components/09-views/02-pages/ThemeContext";

interface Props {
    onCancel?: () => void;
    onError?: (message: string) => void;
    watermarkLabel?: string;
}

interface OwnProps {
    setTheme: (to: Mary.theme.Themes) => void;
    themeIsLoaded: () => Promise<string>;
    routeProps?: string;
}

export const App: React.FunctionComponent<OwnProps> = (props) => {
    const inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    };
    return (
        <ThemeContext setTheme={props.setTheme} routeProps={props.routeProps}>
            <React.Fragment>
                {!inIframe() ?
                    <StandAloneAnonymizer/> : <Anonymizer {...props} />}
            </React.Fragment>
        </ThemeContext>
    );
};

export type AppProps = Props;
