/* eslint-disable @typescript-eslint/restrict-plus-operands */
import * as React from "react";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

export interface ContentLayerRefs {
    layerImgRef: React.RefObject<HTMLCanvasElement>;
    layerWatermarkRef: React.RefObject<HTMLCanvasElement>;
    layerDrawRef: React.RefObject<HTMLCanvasElement>;
    layerOffscreenRef: React.RefObject<HTMLCanvasElement>;

    canvasContainerRef: React.RefObject<HTMLDivElement>;
}
/**
 *
 */
export interface Props
    extends ContentLayerRefs {
    className?: string;
    showContent: boolean;
    isMoving: boolean;
    usingMoveTool: boolean;

    zoomLevel: number;
    offsetX: number;
    offsetY: number;

    canvasWidth: number;
    canvasHeight: number;
    canvasScale: number;

    onStart: (e: React.MouseEvent | React.TouchEvent) => void;
}

/**
 *
 * @param props
 */
export const AnonymizerContent: React.FunctionComponent<Props> =
    (props) => {
        const clazzName = Mary.utils.joinClasses(
            "scl-o-anonymizer__content",
            props.className,

        );

        const getCursor = (): string => {
            if (!props.usingMoveTool) {
                return "crosshair";
            }

            if (props.isMoving) {
                return "grabbing";
            }

            return "grab";  // return "auto" - doesn't seem like we need this
        };

        return (
            <div
                className={clazzName}
                ref={props.canvasContainerRef}
                style={{
                    overflow: "hidden",
                    display: !props.showContent
                        ? "none" : "block",
                }}
            >
                <div
                    style={{
                        transition: "transform .3s ease",
                        transformOrigin: "50% 50%",
                        transformBox: "fill-box",
                        transform: "scale(" + props.zoomLevel + ")",
                        top: "0",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        display: "block",
                        position: "absolute",
                    }}
                >
                    <div
                        style={{
                            transition: props.isMoving ? undefined : "top .3s ease, left .3s ease",
                            top: props.offsetY,
                            left: props.offsetX,
                            width: "100%",
                            height: "100%",
                            display: "block",
                            position: "absolute",
                        }}
                    >
                        <canvas
                            className="scl-h-shadow--big"
                            ref={props.layerImgRef}
                            width={props.canvasWidth}
                            height={props.canvasHeight}
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                zIndex: 1,
                                transformOrigin: "50% 50%",
                                transformBox: "fill-box",
                                transform: "translate(-50%, -50%) scale(" + props.canvasScale + ")",
                            }}
                        />
                        <canvas
                            ref={props.layerWatermarkRef}
                            width={props.canvasWidth}
                            height={props.canvasHeight}
                            style={{
                                transition: "opacity .3s ease",
                                opacity: 0,
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                zIndex: 3,
                                pointerEvents: "none",
                                transformOrigin: "50% 50%",
                                transformBox: "fill-box",
                                transform: "translate(-50%, -50%) scale(" + props.canvasScale + ")",
                            }}
                        />
                        <canvas
                            ref={props.layerDrawRef}
                            width={props.canvasWidth}
                            height={props.canvasHeight}
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transformOrigin: "50% 50%",
                                transformBox: "fill-box",
                                transform: "translate(-50%, -50%) scale(" + props.canvasScale + ")",
                                zIndex: 2,
                                cursor: getCursor(),
                            }}
                            onMouseDown={props.onStart}
                            onTouchStart={props.onStart}
                        />
                        <canvas
                            ref={props.layerOffscreenRef}
                            width={props.canvasWidth}
                            height={props.canvasHeight}
                            style={{
                                display: "none",
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };
