/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/indent */
import * as React from "react";
import { ReduxState } from "../../../../store/ReduxState";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";


/**
 *
 */
interface State {
}

/**
 *
 */
interface Props {
    setTheme: (to: Mary.theme.Themes) => void;
    routeProps?: string;
    children?: React.ReactNode;
}

interface StateProps {
}

interface DispatchProps {
}

/**
 *
 */
const mapStateToProps = (_s: ReduxState): StateProps => ({
});

const mapDispatchToProps = (_dispatch: DispatchFunc): DispatchProps => ({
});

/**
 *
 */
export class ThemeContextComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
    }

    /**
     *
     */
    public componentDidMount() {
        const pathname = this.props.routeProps?.substring(1).split("/")?.[0];

        if(pathname) {
            Object.values(Mary.theme.Themes).map(v => {
                if(pathname === v) {
                    this.props.setTheme(v);
                }
            });
        }
    }

    /**
     *
     */
    public render() {
        return (
           <>{this.props.children}</>
        );
    }
}

/**
 *
 */
 export const ThemeContext = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ThemeContextComp);
