/* eslint-disable */
import {
    Action,
    Store,
    applyMiddleware,
    compose,
    createStore,
} from "redux";
import thunk from "redux-thunk";
import { ActionTypeKeys, Config } from "./ActionTypes";
import { clearReducer, copyReducer } from "./App";
import { totalCountElmReducer, updateCurrentPageIndexReducer, updatePageImgElmReducer, updatePageMarkedReducer, updatePageProcessedFileReducer } from "./Page";
import { ReduxState, ReduxStateInit } from "./ReduxState";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

// DO NOT TOUCH - WE KNOW WHAT WE ARE DOING, I SWEAR
// https://github.com/palantir/tslint/issues/144#issuecomment-195453810
//
// TODO: the disabled lint may or may not be needed here.

/* tslint:disable */
declare let window: any;
const composeEnhancers: any = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* tslint:enable */

/**
 * StoreFunc is an alias for the type of the function that creates the redux
 * store.
 */
type StoreFunc = (conf: Config) => Store<ReduxState>;

/**
 *
 */
const rootReducer =
Mary.utils.withMaryReducers(
    Mary.utils.Reducers
            .create<ActionTypeKeys | Mary.utils.MaryActionTypeKeys, ReduxState>()
            .register(
                ActionTypeKeys.UPDATE_PAGE_IMG,
                updatePageImgElmReducer as Mary.utils.Reducer<ReduxState, Action<ActionTypeKeys.UPDATE_PAGE_IMG>>,
            )
            .register(
                ActionTypeKeys.UPDATE_PAGE_PROCESSED_FILE,
                updatePageProcessedFileReducer as Mary.utils.Reducer<ReduxState, Action<ActionTypeKeys.UPDATE_PAGE_PROCESSED_FILE>>,
            )
            .register(
                ActionTypeKeys.UPDATE_PAGE_MARKED,
                updatePageMarkedReducer as Mary.utils.Reducer<ReduxState, Action<ActionTypeKeys.UPDATE_PAGE_MARKED>>,
            )
            .register(
                ActionTypeKeys.UPDATE_TOTAL_COUNT,
                totalCountElmReducer as Mary.utils.Reducer<ReduxState, Action<ActionTypeKeys.UPDATE_TOTAL_COUNT>>,
            )
            .register(
                ActionTypeKeys.UPDATE_CURRENT_PAGE_INDEX,
                updateCurrentPageIndexReducer as Mary.utils.Reducer<ReduxState, Action<ActionTypeKeys.UPDATE_CURRENT_PAGE_INDEX>>,
            )
            .register(
                ActionTypeKeys.COPY,
                copyReducer as Mary.utils.Reducer<ReduxState, Action<ActionTypeKeys.COPY>>,
            )
            .register(
                ActionTypeKeys.CLEAR_STATE,
                clearReducer as Mary.utils.Reducer<ReduxState, Action<ActionTypeKeys.CLEAR_STATE>>,
            ),
    );

/**
 *
 * @param env
 */
export const createReduxStore:
StoreFunc =
    conf => createStore<ReduxState, Action<ActionTypeKeys | Mary.utils.MaryActionTypeKeys>, {}, {}>(
        (s, a) => rootReducer.reduce(s || Mary.utils.State.create(ReduxStateInit(conf)), a),
        composeEnhancers(applyMiddleware(thunk)),
    );
