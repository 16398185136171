/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/indent */
import * as PDFjs from "pdfjs-dist";
import * as React from "react";

import { AnonymizerWrapper, AnonymizerPagination } from "./Anonymizer/AnonymizerWrapper";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { Page, ReduxState } from "../../../../store/ReduxState";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../../store/ActionTypes";
import {
    hasChanged,
    totalCount, updateCurrentPageIndex, updatePageImgElm, updatePageProcessedFile } from "../../../../store/Page";
import { clear } from "../../../../store/App";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";


/**
 *
 */
interface State {
    loader: FileReader;
    loading: boolean;
    saveAllPages: boolean;
    themeIsLoaded: boolean;
    file?: Blob;
    watermarkLabel?: string;
}

/**
 *
 */
interface OwnProps {
    // setTheme: (to: Mary.theme.Themes) => void;
    themeIsLoaded: () => Promise<string>;
    routeProps?: string;
}

interface StateProps {
    pages: Page[];
    totalPageCount: number;
    currentPageIndex: number;
    hasChanged: boolean;
}

interface DispatchProps {
    updatePageImgElm: (img: HTMLImageElement, proccessedImg: string) => void;
    updatePageProcessedFile: (file: string) => void;
    updateTotalCount: (count: number) => void;
    updateCurrentPageIndex: (index: number) => void;
    clearState: () => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    pages: s.prop("pages"),
    totalPageCount: s.prop("totalPageCount"),
    currentPageIndex: s.prop("currentPageIndex"),
    hasChanged: hasChanged(s),
});

const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    updatePageImgElm: (img: HTMLImageElement, proccessedImg: string) => {
        dispatch(updatePageImgElm(img, proccessedImg));
    },
    updatePageProcessedFile: (file: string) => {
        dispatch(updatePageProcessedFile(file));
    },
    updateTotalCount: (count: number) => {
        dispatch(totalCount(count));
    },
    updateCurrentPageIndex: (index: number) => {
        dispatch(updateCurrentPageIndex(index));
    },
    clearState: () => {
        dispatch(clear());
    },
});

/**
 *
 */
export class AnonymizerLoaderComp
    extends React.Component<Props, State> {

    /**
     *
     */
    private layerPDFRef: React.RefObject<HTMLCanvasElement>;

    public constructor(props: Props) {
        super(props);

        this.initState = this.initState.bind(this);
        this.layerPDFRef = React.createRef();
        this.loadImages = this.loadImages.bind(this);
        this.loadPages = this.loadPages.bind(this);
        this.onSave = this.onSave.bind(this);

        this.state = this.initState();

        this.onReceivedMessage = this.onReceivedMessage.bind(this);
        this.triggerOnSave = this.triggerOnSave.bind(this);
        this.triggerOnError = this.triggerOnError.bind(this);
        this.triggerOnCancel = this.triggerOnCancel.bind(this);
    }

    /**
     *
     */
    public componentDidMount() {
        window.addEventListener(
            "message", this.onReceivedMessage, false);

        if (this.inIframe()) {
            window.parent.postMessage({
                type: "claude:anonymiser:loaded",
                data: true,
            }, "*");
        }
    }

    /**
     *
     */
    public componentDidUpdate(prevProps: Props, prevState: State) {
        if((prevState.themeIsLoaded !== this.state.themeIsLoaded) && this.state.themeIsLoaded) {
            this.loadImages();
        }
    }

    public componentWillUnmount() {
        window.removeEventListener(
            "message", this.onReceivedMessage, false);
    }

    /**
     *
     */
    public render() {
        return (
            <Mary.utils.BreakpointProvider>
               <Mary.utils.BreakpointConsumer>
                    {({ width, height }) => (
                          <Mary.base.Div
                            theme={{
                                shadow: Mary.theme.ThemeShadowSizes.TINY,
                            }}
                            style={{ width: `${!this.inIframe() ? 769 : width}px`,
                            height: `${!this.inIframe() ? 500 : height}px`, position: "relative", ...{
                                margin: !this.inIframe() ? "0 auto" : undefined,
                                marginTop: !this.inIframe() ? "30px" : undefined,
                                display: !this.inIframe() ? "inline-block" : undefined,
                            }}}>
                                <canvas
                                    ref={this.layerPDFRef}
                                    style={{
                                        display: "none",
                                    }}
                                />
                                <AnonymizerWrapper
                                    {...this.props}
                                    onCancel={this.triggerOnCancel}
                                    onError={this.triggerOnError}
                                    watermarkLabel={this.state.watermarkLabel}
                                    width={!this.inIframe() ? 769 : width}
                                    height={!this.inIframe() ? 500 : height}
                                    sourceImage={this.getCurrentImage()}
                                    onSave={this.onSave}
                                    {...this.getPagination()}
                                    loading={this.state.loading}
                                    saving={this.state.saveAllPages}
                                />
                            </Mary.base.Div>
                        )}
                </Mary.utils.BreakpointConsumer>
            </Mary.utils.BreakpointProvider>
        );
    }

    private inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    };

    private onReceivedMessage(ev: MessageEvent) {
        if (ev.data.type === "claude:anonymiser:init") {
            console.log("Claude Anonymiser has received the init call with the file");
            this.initLoad({
                watermarkLabel: ev.data.data.watermarkLabel,
                file: ev.data.data.file,
            });
        }
    }

    private triggerOnSave(files: string[], hasChanged: boolean) {
        if (this.inIframe()) {
            window.parent.postMessage({
                type: "claude:anonymiser:onsave",
                data: {
                    files: files,
                    hasChanged: hasChanged,
                },
            }, "*");
        }
    }

    private triggerOnCancel() {
        if (this.inIframe()) {
            window.parent.postMessage({
                type: "claude:anonymiser:oncancel",
                data: true,
            }, "*");
        }
    }

    private triggerOnError(message: string) {
        if (this.inIframe()) {
            window.parent.postMessage({
                type: "claude:anonymiser:onerror",
                data: message,
            }, "*");
        }
    }

    private loadPages(pdf: any) {
        pdf.getPage(this.props.pages.length + 1).then((page: any) => {
            const scale = 1.5;
            const viewport = page.getViewport({scale});
            //
            // Prepare canvas using PDF page dimensions
            //
            const canvas = Mary.utils.ensure(this.layerPDFRef.current || undefined);
            const context = Mary.utils.ensure(canvas.getContext("2d") || undefined);

            canvas.height = viewport.height;
            canvas.width = viewport.width;
            //
            // Render PDF page into canvas context
            //
            const task = page.render({ canvasContext: context, viewport: viewport });
            task.promise.then(() => {
                const imageUrl = canvas.toDataURL("image/jpeg");
                const imageObj = new Image();
                // eslint-disable-next-line max-len
                imageObj.src = imageUrl;
                imageObj.onload = () => {
                    try {
                        this.props.updatePageImgElm(imageObj, imageUrl);
                        if (this.props.totalPageCount !== this.props.pages.length) {
                            this.loadPages(pdf);
                        }
                    } catch (_e) {
                        this.triggerOnError("The PDF file page or it's content is not valid");
                    }
                };
            }).catch((_err: any) => {
                this.triggerOnError("The PDF file page or it's content is not valid");
            });
        });
    }

    /**
     *
     * @param evt
     */
    private loadImages() {
        PDFjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
        // Make a resetAnonymizer function
        const loader = Mary.utils.ensure(this.state.loader);
        if (this.state.file) {
            loader.readAsDataURL(this.state.file);
            if (this.state.file.type && this.state.file.type === "application/pdf") {
                loader.onload = () => {
                    PDFjs.getDocument(loader.result as any)
                    .promise.then((pdf: any) => {
                        this.props.updateTotalCount(pdf.numPages);
                        this.loadPages(pdf);
                    }).catch((_err: any) => {
                        this.triggerOnError("The PDF file or it's content is not valid");
                    });
                };
            } else {
                loader.onload = () => {
                    const dataURL = loader.result as string;
                    const imageObj = new Image();
                    imageObj.src = dataURL;
                    imageObj.onload = () => {
                        this.props.updateTotalCount(1);
                        this.props.updatePageImgElm(imageObj, dataURL);
                    };
                };
            }
        } else  {
            this.triggerOnError("The file or it's content is not valid");
        }

    }

    private getCurrentImage(): HTMLImageElement {
        return this.props.pages[this.props.currentPageIndex]?.img;
    }

    private getPagination(): AnonymizerPagination | undefined {
        if (this.props.totalPageCount <= 1) {
            return { pagination: undefined };
        } else {
            return {
                pagination: {
                    total: this.props.totalPageCount,
                    current: this.props.currentPageIndex + 1,
                },
            };
        }
    }

    private onSave(image: string[], nextPage: number) {
        if (image && image[0]) {
            // Always process the file!
            if (this.state.saveAllPages) {
                this.props.updatePageProcessedFile(image[0]);
            }
            if ((nextPage) === this.props.totalPageCount) {
                // We check if we are saving all (or 1) page(s) if we do, we trigger the onSave
                if (this.state.saveAllPages) {
                     const processedFiles = this.props.pages.map(p => p.processedFile);
                     this.triggerOnSave(processedFiles, this.props.hasChanged);
                } else {
                    /**
                     * Only triggered when you are on the last page (or only page)
                     */
                    this.setState({
                        saveAllPages: true,
                    }, () => {
                        this.props.updateCurrentPageIndex(0);
                    });
                }
            } else {
                this.props.updateCurrentPageIndex(nextPage);
            }
        } else {
            this.triggerOnError("No result after anonymizing");
        }
    }

    private initLoad(config: { file: Blob; watermarkLabel?: string}){
        this.props.clearState();
        this.setState({
            ...this.initState(),
            ...config,
        }, () => {
                this.props.themeIsLoaded()
                .then(() => {
                    this.setState({
                        themeIsLoaded: true,
                    });
                }).catch(() => {
                    this.setState({
                        themeIsLoaded: false,
                    });
                    this.triggerOnError("The theme could not be applied.");
                });
        });
    }

    private initState(): State {
        return {
            loading: true,
            loader: new FileReader(),
            themeIsLoaded: false,
            saveAllPages: false,
        };
    }
}

/**
 *
 */
 export const Anonymizer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AnonymizerLoaderComp);
