/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Container, Div, Grid } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/03-base";
import { FormField } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms/Form/Field";
import { Page } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/09-views/01-sections";
import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import { Summary } from "./Summary";
import { Card,
    FormGroup,
    RTE,
    ValidationFeedback,
    fieldValidationIcon,
    fieldValidationMessage,
    hasValueValidation } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms";
import { ThemePalette, ThemePaletteState, ThemeShadowSizes } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/theme/_Types";
import { Button } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms/Button";
import { Blockquote, Icon, IconNames } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms";
import { DragNDrop, FileErrorMessageDisplay } from "../../07-organisms/DragNDrop";
import { ErrorCode, FileRejection } from "react-dropzone";
import { ValidationStatus } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms/Form/_Types";

interface ImageDimension {
    width: number;
    height: number;
}


export const StandAloneAnonymizer: React.FC<{}> = () => {
    const [store, setStore] = useState({
        images: [],
        errors: [] as FileRejection[] |  undefined,
        watermark: "",
        hasStarted: true,
        inProgress: false,
        hasCompleted: false,
        hasError: false,

    });

    const onFileSelected = (getBinary: File) => {
        const current = document.getElementById("anonymiser");
        if (current) {
            current?.["contentWindow"]?.postMessage(
                {
                    type: "claude:anonymiser:init",
                    data: {
                        file: getBinary,
                        watermarkLabel: store.watermark, // Whatever watermark you prefer, watermarkLabel is optional
                    },
                },
                "*",
            );
        }
        setStore({
            ...store,
            inProgress: true,
            hasStarted: false,
        });
    };

    const A4_PAPER_DIMENSIONS = {
        width: 210,
        height: 297,
    };

    const imageDimensionsOnA4 = (dimensions: ImageDimension) => {
        const isLandscapeImage = dimensions.width >= dimensions.height;

        if (isLandscapeImage) {
            return {
                width: A4_PAPER_DIMENSIONS.width,
                height: A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
            };
        }

        return undefined;
    };

    const generatePdfFromImages = (images: HTMLImageElement[]) => {
        const doc = new jsPDF();
        doc.deletePage(1);

        images.forEach((image) => {
            const imageDimensions = imageDimensionsOnA4({
                width: image.width,
                height: image.height,
            });

            doc.addPage();
            doc.addImage(
                image.src,
                imageDimensions && imageDimensions.width
                    ? (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2 : 0,
                imageDimensions && imageDimensions.height
                    ? (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2 : 0,
                imageDimensions && imageDimensions.width ? imageDimensions.width : 0,
                imageDimensions && imageDimensions.height ? imageDimensions.height : 0,
            );
        });

        const pdfURL = doc.output("bloburl");
        window.open(pdfURL as any, "_self");
    };

    useEffect(() => {
        const onAnonymiserEvent = (ev: MessageEvent<any>) => {
            if (ev.data.type === "claude:anonymiser:loaded") {
                console.log("onLoaded(): Claude Anonymizer is loaded");
            }

            if (ev.data.type === "claude:anonymiser:onsave") {
                console.log("onSave(file, haschanged): ", ev.data.data);
                // ev.data.data = {file, haschanged};
                const imgArr = ev.data.data?.files.map((f: string) => ({
                    src: f,
                }));

                setStore({
                    ...store,
                    images: imgArr,
                    hasCompleted: true,
                    inProgress: false,
                    hasStarted: false,
                });
            }

            if (ev.data.type === "claude:anonymiser:oncancel") {
                console.log("onCancel(): ", ev.data.data);
                setStore({
                    ...store,
                    images: [],
                    hasStarted: true,
                    inProgress: false,
                    hasCompleted: false,
                });
            }

            if (ev.data.type === "claude:anonymiser:onerror") {
                console.log("onError(err)", ev.data.data);
                setStore({
                    ...store,
                    images: [],
                    hasStarted: true,
                    inProgress: false,
                    hasCompleted: false,
                    hasError: true,
                });
                // where ev.data.data = err
            }
        };

        window.addEventListener(
            "message", onAnonymiserEvent, false);

        return () => {
            window.removeEventListener("message", onAnonymiserEvent);
        };
    }, []);

    const validateAnonymiseFile: (value: string | number) => ValidationFeedback = (_v) => {
        if (store.errors && store.errors.length > 0) {
            return ({
                status: ValidationStatus.ERROR,
                message: (
                    store.errors.map((f, index) => {
                        const name = f.file.name;
                        return (
                            <div key={`${name}-${index}`}>
                                <b>{name}:</b>
                                {f.errors.map(e => FileErrorMessageDisplay[e.code as ErrorCode]).join(",")}<br/>
                            </div>
                        );
                    })
                ),
            });
        }
        if (store.images && store.images.length > 0) {
            return ({
                status: ValidationStatus.SUCCESS,
            });
        } else {
            return ({
                status: ValidationStatus.DEFAULT,
            });
        }
    };
    const anonymiseFileProps = {
        value: store.images || store.errors ? "yes" : undefined,
        validationFunction: validateAnonymiseFile,
    };

    return (
        <Page>
            <Container
                theme={{
                    padding: { "": { y: 3 }, "sm": { y: 4 } },
                }}
            >
                <Div theme={{ shadow: ThemeShadowSizes.TINY}}>
                    <Card
                        title={
                            store.hasStarted ? "Anonimiseren 1/3"
                                : store.inProgress ? "Anonimiseren 2/3"
                                    : "Anonimiseren 3/3"
                        }
                        collapsable={false}
                        defaultCollapsed={false}
                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                    >
                        {(store.hasStarted || store.hasCompleted) && (
                            <>
                                {store.hasError && <Blockquote
                                    theme={{ paletteState: ThemePaletteState.DANGER}} background>
                                    <RTE>
                                        Er is iets misgegaan, probeer opnieuw. <br/>
                                    </RTE>
                                </Blockquote>}
                                <Container
                                    theme={{
                                        padding: { "": { y: 3 }, "sm": { y: 4 } },
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                    }}
                                >
                                    {store.hasStarted &&
                    <>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 6 }}
                            >
                                <FormField
                                    label="Watermark label"
                                    placeholder="Voer watermark label in"
                                    onChange={(value?: string | number) => setStore({
                                        ...store, watermark: value?.toString() ?? ""})}
                                    value={store.watermark}
                                    validationFunction={
                                        (value: string | number) =>
                                            hasValueValidation(value,
                                                "Watermark label ingevuld.")}
                                />
                            </Grid>
                        </Div>
                        <FormGroup {...anonymiseFileProps}>
                            <Div theme={{margin: {
                                "": { b: 4 },
                            }}}>
                                <DragNDrop
                                    onDropRejected={(e) => {
                                        setStore({...store, images: [], errors: e, hasError: true });
                                    }}
                                    onDropAccepted={(e) => {
                                        console.log(e);
                                        onFileSelected(e[0]);
                                    }}
                                    maxFiles={1}
                                    maxSize={10000000}
                                    accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                />
                            </Div>
                            {fieldValidationMessage(anonymiseFileProps) &&
    (
        <>
            {console.log(fieldValidationMessage(anonymiseFileProps), "hola")}
            <div className="scl-o-form__info">
                <div className="scl-o-form__info-text">
                    {fieldValidationMessage(anonymiseFileProps)}
                </div>
                <div className="scl-o-form__info-icon">
                    {fieldValidationIcon(anonymiseFileProps)}
                </div>
            </div>
        </>
    )}
                        </FormGroup>
                    </>
                                    }
                                    {store.hasCompleted &&
                <Summary generatePDF={() => generatePdfFromImages(store.images)}/>
                                    }
                                </Container>
                                {store.hasCompleted && (
                                    <div className={"scl-o-anonymizer__navigation"}>
                                        <div className="scl-o-anonymizer__navigation-content">
                                            <div className="scl-o-anonymizer__navigation-content--right">
                                                <div className="scl-m-btn-container scl-m-btn-container--right"
                                                    style={{ textAlign: "right"}}>
                                                    <Button
                                                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                                        className="scl-a-btn--big"
                                                        buttonType="button"
                                                        link={{ onClick: () => window.location.reload()}}
                                                    >
                                            Ga verder
                                            &nbsp;&nbsp;
                                                        <Icon name={IconNames.ARROW_RIGHT} />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}</>
                        )}
                        <iframe
                            id="anonymiser"
                            src={window.location.href}
                            style={{
                                border: 0,
                                height: "calc(100vh - 150px)",
                                width: "100%",
                                display: store.inProgress ? "block" : "none",
                            }}
                        />
                    </Card>
                </Div>
            </Container>
        </Page>
    );
};
