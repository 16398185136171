import * as React from "react";
import { Div, Grid } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/03-base";
import { IconNames } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms";
import { InfoCard } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/06-molecules";
/**
 *
 */
interface Props {
    generatePDF: () => void;
}

/**
 *
 */
export const Summary: React.FC<Props> = (props) => (
    <>
        <Div className="scl-b-row">
            <Grid
                size={{ xs: 12, md: 4 }}
                offset={{ xs: 0, md: 4 }}
            >
                <InfoCard
                    title={"Download"}
                    description="U kunt de geanonimiseerde pdf nu downloaden"
                    link={{ onClick: props.generatePDF }}
                    icon={IconNames.DOWNLOAD}
                />
            </Grid>
        </Div>
    </>
);
