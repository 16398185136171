import * as React from "react";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { AnonymizerContent, AnonymizerNavigation, AnonymizerToolbar, ContentLayerRefs } from "./Anonymizer/index";
import { EnvIndicatorBar } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/06-molecules";

interface Toolbar {
    className?: string;

    onUseDrawTool: (e: React.MouseEvent | React.TouchEvent) => void;
    usingDrawTool: boolean;

    onUseMoveTool: (e: React.MouseEvent | React.TouchEvent) => void;

    onZoomOut: (e: React.MouseEvent | React.TouchEvent) => void;
    disableZoomOut: boolean;

    onZoomIn: (e: React.MouseEvent | React.TouchEvent) => void;
    disableZoomIn: boolean;

    onUndo: (e: React.MouseEvent | React.TouchEvent) => void;
    disableUndo: boolean;

    onCopy: (e: React.MouseEvent | React.TouchEvent) => void;
    disableCopyBtn: boolean;
    isMultiPage: boolean;

    onClear: (e: React.MouseEvent | React.TouchEvent) => void;
    disableClearBtn: boolean;
}

interface Content extends ContentLayerRefs {
    className?: string;
    zoomLevel: number;
    isMoving: boolean;
    canvasWidth: number;
    canvasHeight: number;
    canvasScale: number;
    offsetY: number;
    offsetX: number;
}

interface Navigation {
    className?: string;
    onSave: (nextPage: number) => void;
    onCancel: (e: React.MouseEvent | React.TouchEvent) => void;
    totalPageCount: number;
    currentPageIndex: number;
    disableSave: boolean;
    isMultiPage: boolean;
    disableContinue?: boolean;
    saveButtonLabel?: string | React.ReactNode;
}
/**
 *
 */
interface Props {
    className?: string;
    style?: React.CSSProperties;
    compact?: boolean;
    usingMoveTool: boolean;

    onEnd: (e: React.MouseEvent | React.TouchEvent) => void;
    onMove: (e: React.MouseEvent | React.TouchEvent) => void;
    onStart: (e: React.MouseEvent | React.TouchEvent) => void;

    loadingIndication: {
        loading: boolean;
        saving: boolean;
        cancelling: boolean;
    };

    toolbar: Toolbar;
    content: Content;
    navigation: Navigation;
}

/**
 *
 * @param props
 */
export const AnonymizerUI: React.FunctionComponent<Props> =
    (props) => {
        const clazzName = Mary.utils.joinClasses(
            "scl-o-anonymizer",
            "scl-h-no-select",
            props.compact ? "scl-o-anonymizer--compact" : undefined,
            props.className,

        );

        const isLoading = props.loadingIndication.loading
            || props.loadingIndication.cancelling
            || props.loadingIndication.saving;

        const getLoadingMessage = props.loadingIndication.loading
            ? Mary.utils.getText("app.loadingMessage", "Loading message")
            : (
                props.loadingIndication.cancelling
                    ? Mary.utils.getText("app.cancellingMessage", "Cancelling message")
                    : Mary.utils.getText("app.savingMessage", "Saving message")
            );

        return (
            <div
                className={clazzName}
                style={{
                    ...props.style,
                    // TODO: Remove the inline styles after Scarlet 1.0.2 is released.
                    touchAction: "none",
                    WebkitTouchCallout: "none",
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    msUserSelect: "none",
                    userSelect: "none",
                    height: "100%",
                }}

                onMouseMove={props.onMove}
                onMouseLeave={props.onEnd}
                onMouseUp={props.onEnd}
                onTouchEnd={props.onEnd}
                onTouchMove={props.onMove}
            >
                <React.Fragment>
                    <EnvIndicatorBar />
                    {isLoading ?
                        (
                            <Mary.atoms.loadingindicator.LoadingIndicator
                                type={Mary.atoms.loadingindicator.LoadingIndications.DEFAULT}
                                theme={{ palette: Mary.theme.ThemePalette.BRAND_PRIMARY }}
                            >
                                <Mary.base.Div
                                    theme={{ padding: { "": { t: 1 }, "sm": { t: 1 } } }}
                                    className="scl-h-text--tiny scl-h-text--greyed-out"
                                >
                                    {getLoadingMessage}
                                </Mary.base.Div>
                            </Mary.atoms.loadingindicator.LoadingIndicator>
                        ) : (
                            <AnonymizerToolbar
                                usingMoveTool={props.usingMoveTool}
                                {...props.toolbar}
                            />
                        )}
                    <AnonymizerContent
                        showContent={!isLoading}
                        onStart={props.onStart}
                        usingMoveTool={props.usingMoveTool}
                        {...props.content}
                    />

                    {!isLoading &&
                        <AnonymizerNavigation
                            {...props.navigation}
                            compact={props.compact}
                        />
                    }
                </React.Fragment>
            </div>
        );
    };
