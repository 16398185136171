import { Stack } from "immutable";
import { Action } from "redux";
import { Rect } from "../App/components/09-views/02-pages/Anonymizer/AnonymizerWrapper";
import { ActionTypeKeys, Config, ReducerFunc } from "./ActionTypes";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

export interface Page {
    img: HTMLImageElement;
    marked: Stack<Rect>;
    processedFile: string;
}

/**
 *
 */
interface TState {
    conf: Config;
    pages: Page[];
    totalPageCount: number;
    currentPageIndex: number;
    mary?: Mary.utils.MaryState;
}

/**
 *
 */
export type ReduxState = Mary.utils.State<TState>;

/**
 *
 */
export type ReducerFn<A extends Action<ActionTypeKeys>> =
    ReducerFunc<ReduxState, A>;

export const clearState = (s: ReduxState) => s.clear({mary: true, conf: true});

/**
 *
 */
export const ReduxStateInit: (conf: Config) => Mary.utils.StateInit<TState> =
    c => ((): TState => ({
        conf: c,
        pages: [],
        totalPageCount: 0,
        currentPageIndex: 0,
        mary: undefined,
    }));

export const initTestState: ReduxState = Mary.utils.State.create(ReduxStateInit({apiEndpoint: ""}));
